import { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { Paragraph } from "@unlikelyai-magic/ui/typography";

const ExplanationTooltipContainer = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.component.border[2]};
  border-radius: 0.625rem;
  background-color: ${({ theme }) => theme.colors.background.default};
  padding: ${({ theme }) => theme.spacings.sm};
  position: absolute;
  z-index: 1;
`;

export const ExplanationTooltip = () => {
  const [tooltipPosition, setTooltipPosition] = useState({ x: 0, y: 0 });

  useEffect(() => {
    const handleMouseMove = (event: MouseEvent) =>
      // offset the tooltip to the bottom of the cursor
      setTooltipPosition({ x: event.clientX - 60, y: event.clientY + 20 });
    document.addEventListener("mousemove", handleMouseMove);
    return () => document.removeEventListener("mousemove", handleMouseMove);
  }, []);

  return (
    <ExplanationTooltipContainer
      style={{ top: tooltipPosition.y, left: tooltipPosition.x }}
    >
      <Paragraph small>Click to view graph</Paragraph>
    </ExplanationTooltipContainer>
  );
};
