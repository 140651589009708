import { useEffect, useRef, useState } from "react";
import { PrimaryButton, SecondaryButton } from "@dip/ui/components/buttons";
import styled from "@emotion/styled";
import { ExclamationCircleIcon } from "@heroicons/react/24/outline";
import { MinusIcon, PlusCircleIcon } from "@heroicons/react/24/solid";
import { Form, Input, Typography } from "antd";
import { TextAreaRef } from "antd/lib/input/TextArea";
import { Spacing } from "@unlikelyai-magic/ui/layouts";
import { Paragraph } from "@unlikelyai-magic/ui/typography";
import { StepLayout } from "../layouts";

const HeaderText = styled(Paragraph)`
  font-size: 1.25rem;
`;

const SecondaryText = styled(Paragraph)`
  color: ${({ theme }) => theme.colors.text.secondary.default};
`;

const ErrorContainer = styled(Spacing)`
  color: ${({ theme }) => theme.colors.semantic.error.default};
`;

const ErrorText = styled(Typography.Text)`
  color: inherit;
`;

const ExclamationCircleIconContainer = styled(ExclamationCircleIcon)`
  color: inherit;
  height: 1.5rem;
`;

const TextArea = styled(Input.TextArea)`
  border-radius: 0.375rem;
  resize: none;
`;

const MinusButton = styled(SecondaryButton)`
  border: none;
  padding: 0.5rem;
  & svg {
    width: 1.25rem;
    height: 1.25rem;
  }
`;

interface DomainStepProps {
  onGoBack: () => void;
  onGoForward: () => void;
}

export const DomainInputStep = ({ onGoBack, onGoForward }: DomainStepProps) => {
  const form = Form.useFormInstance();
  const [hasError, setHasError] = useState(false);

  const domainDescriptionFieldName = "domainDescription";
  const generalExclusionsFieldName = "generalExclusions";

  const [
    isGeneralExclusionsTextBoxVisible,
    setIsGeneralExclusionsTextBoxVisible,
  ] = useState(Boolean(form.getFieldValue(generalExclusionsFieldName)));

  // The ref is used to focus the input when the component mounts
  const inputRef = useRef<TextAreaRef>(null);
  useEffect(() => {
    if (inputRef.current) inputRef.current.focus();
  }, []);

  const isDomainInputError = async () => {
    try {
      await form.validateFields([domainDescriptionFieldName]);
      setHasError(false);
      return false;
    } catch (errorInfo) {
      setHasError(true);
      return true;
    }
  };

  const handleDomainInputChange = async () => await isDomainInputError();

  const handleContinue = async () =>
    !(await isDomainInputError()) && onGoForward();

  return (
    <StepLayout
      title={<HeaderText>Description</HeaderText>}
      headerDescription={
        <Spacing gap="lg">
          <SecondaryText>
            Provide the domain description you would like understood.
          </SecondaryText>
          {hasError && (
            <ErrorContainer gap="xs" direction="horizontal" items="center">
              <ExclamationCircleIconContainer fontSize={4} />
              <ErrorText>Please produce a domain description.</ErrorText>
            </ErrorContainer>
          )}
          <Form.Item
            noStyle
            name={domainDescriptionFieldName}
            rules={[{ required: true }]}
            validateTrigger="onChange"
            help=""
          >
            <TextArea
              ref={inputRef}
              rows={15}
              onChange={handleDomainInputChange}
            />
          </Form.Item>
          {isGeneralExclusionsTextBoxVisible ? (
            <>
              <Spacing gap="sm">
                <Spacing
                  direction="horizontal"
                  justify="space-between"
                  items="center"
                >
                  <HeaderText>General exclusions</HeaderText>
                  <MinusButton
                    icon={<MinusIcon />}
                    onClick={() => {
                      form.setFieldValue(generalExclusionsFieldName, undefined);
                      setIsGeneralExclusionsTextBoxVisible(false);
                    }}
                  />
                </Spacing>
                <SecondaryText>
                  Add any rules which apply to all questions.
                </SecondaryText>
              </Spacing>
              <Form.Item noStyle name={generalExclusionsFieldName}>
                <TextArea rows={15} />
              </Form.Item>
            </>
          ) : (
            <SecondaryButton
              icon={<PlusCircleIcon />}
              onClick={() => setIsGeneralExclusionsTextBoxVisible(true)}
            >
              Add general exclusions
            </SecondaryButton>
          )}
          <PrimaryButton onClick={handleContinue}>Continue</PrimaryButton>
        </Spacing>
      }
      onGoBack={onGoBack}
    />
  );
};
