import {
  AnswerVM,
  LlSolution,
  SolverInfo,
  UlSolution,
  filterToValidAnswers,
} from "../../reasoning-engine/reasoning-engine";
import { QuestionState } from "../../reasoning-engine/states/question";
import { solutionsFrom } from "./graph-traversal";
import {
  GraphStructure,
  NodeType,
  NodeWithNeighbours,
  SolverDetails,
} from "./reasoning-graph-types";

const ROOT_ID = "root";

/**
 * Create a compound ID based on the solver and the path to solution. This is required for a denormalised graph so solvers taken more a cache (that would normally repeat in the graph) are modelled as separate nodes.
 */
export const compoundId = (childId: string, parentId?: string) => {
  return (parentId ?? "") + childId;
};

export const solverPathAndDetailsFrom = (
  solution: UlSolution | AnswerVM,
  solutionToSolverMap: Map<string, LlSolution[]>,
  parentSolverId?: string,
  parentSolutionId?: string
): SolverDetails[] => {
  // A solution can come from multiple solvers but only one of those was the original executing solver, the rest are cached solvers. In this case we are looking for the solution that was at the top of the query tree and this will be the non cached solver.
  const topSolution = solutionToSolverMap
    .get(solution.solutionId)
    ?.find(
      (solution) =>
        solution.solverNodeId == solution.executingSolverNodeId ||
        solution.executingSolverNodeId == null
    );
  const solverNodeId =
    (solution as UlSolution).solverNodeId ?? topSolution?.solverNodeId;
  const executingSolverId =
    (solution as UlSolution).executingSolverNodeId ??
    topSolution?.executingSolverNodeId;
  const compoundSolverId = compoundId(solverNodeId, parentSolverId);
  const compoundExecutingSolverId = compoundId(
    executingSolverId,
    parentSolverId
  );
  const compoundSolutionId = compoundId(solution.solutionId, parentSolutionId);

  const childSolutions = solutionsFrom(solution.explanation);
  switch (solution.explanation["@type"]) {
    case "AND":
      return [
        ...childSolutions.flatMap((child) =>
          solverPathAndDetailsFrom(
            child,
            solutionToSolverMap,
            compoundSolverId,
            compoundSolutionId
          )
        ),
        {
          id: compoundSolverId,
          executingId: compoundExecutingSolverId,
          solutionId: compoundSolutionId,
          parentSolutionId: parentSolutionId,
          ulConclusion: solution.explanation.conclusion,
          solverType: NodeType.AND_SOLVER,
        },
      ];
    case "OR":
      return [
        ...childSolutions.flatMap((child) =>
          solverPathAndDetailsFrom(
            child,
            solutionToSolverMap,
            compoundSolverId,
            compoundSolutionId
          )
        ),
        {
          id: compoundSolverId,
          executingId: compoundExecutingSolverId,
          solutionId: compoundSolutionId,
          parentSolutionId: parentSolutionId,
          ulConclusion: solution.explanation.conclusion,
          ulDescription: solution.explanation.solvedClause,
          solverType: NodeType.OR_SOLVER,
        },
      ];
    case "EXISTS":
      return [
        ...childSolutions.flatMap((child) =>
          solverPathAndDetailsFrom(
            child,
            solutionToSolverMap,
            compoundSolverId,
            compoundSolutionId
          )
        ),
        {
          id: compoundSolverId,
          executingId: compoundExecutingSolverId,
          solutionId: compoundSolutionId,
          parentSolutionId: parentSolutionId,
          ulConclusion: solution.explanation.conclusion,
          solverType: NodeType.OTHER_SOLVER,
        },
      ];
    case "BACKWARD_CHAINING":
      return [
        ...childSolutions.flatMap((child) =>
          solverPathAndDetailsFrom(
            child,
            solutionToSolverMap,
            compoundSolverId,
            compoundSolutionId
          )
        ),
        {
          id: compoundSolverId,
          executingId: compoundExecutingSolverId,
          solutionId: compoundSolutionId,
          parentSolutionId: parentSolutionId,
          ulConclusion: solution.explanation.conclusion,
          ulDescription: solution.explanation.reasoningPassage,
          solverType: NodeType.DEDUCTION,
        },
      ];
    case "HYPOTHETICAL":
      return [
        ...childSolutions.flatMap((child) =>
          solverPathAndDetailsFrom(
            child,
            solutionToSolverMap,
            compoundSolverId,
            compoundSolutionId
          )
        ),
        {
          id: compoundSolverId,
          executingId: compoundExecutingSolverId,
          solutionId: compoundSolutionId,
          parentSolutionId: parentSolutionId,
          ulConclusion: solution.explanation.conclusion,
          ulDescription: { elements: solution.explanation.context },
          solverType: NodeType.OTHER_SOLVER,
        },
      ];
    case "SEMANTIC_EQUIVALENCE": {
      // De Morgan solvers are wrapped as Semantic Equivalence solvers
      // They have empty equivalence passages used which is how they can be identified
      const isDeMorganSolver =
        solution.explanation.equivalencePassagesUsed.length == 0;

      // Semantic equivalence is just a wrapper and not a real solver
      return [
        ...childSolutions.flatMap((child) =>
          solverPathAndDetailsFrom(
            child,
            solutionToSolverMap,
            solverNodeId,
            compoundSolutionId
          )
        ),
        ...(isDeMorganSolver
          ? [
              {
                id: compoundSolverId,
                executingId: compoundExecutingSolverId,
                solutionId: compoundSolutionId,
                parentSolutionId: parentSolutionId,
                ulConclusion: solution.explanation.conclusion,
                solverType: NodeType.OTHER_SOLVER,
              },
            ]
          : []),
      ];
    }
    case "PROOF_BY_CONTRADICTION":
      return [
        ...childSolutions.flatMap((child) =>
          solverPathAndDetailsFrom(
            child,
            solutionToSolverMap,
            compoundSolverId,
            compoundSolutionId
          )
        ),
        {
          id: compoundSolverId,
          executingId: compoundExecutingSolverId,
          solutionId: compoundSolutionId,
          parentSolutionId: parentSolutionId,
          ulConclusion: solution.explanation.conclusion,
          solverType: NodeType.OTHER_SOLVER,
        },
      ];
    case "REDUCER":
      return [
        ...childSolutions.flatMap((child) =>
          solverPathAndDetailsFrom(
            child,
            solutionToSolverMap,
            compoundSolverId,
            compoundSolutionId
          )
        ),
        {
          id: compoundSolverId,
          executingId: compoundExecutingSolverId,
          solutionId: compoundSolutionId,
          parentSolutionId: parentSolutionId,
          ulConclusion: solution.explanation.conclusion,
          ulDescription: solution.explanation.reducerPassageUsed,
          solverType: NodeType.OTHER_SOLVER,
        },
      ];
    case "COMPUTED":
      return [
        {
          id: compoundSolverId,
          executingId: compoundExecutingSolverId,
          solutionId: compoundSolutionId,
          parentSolutionId: parentSolutionId,
          ulConclusion: solution.explanation.conclusion,
          ulDescription: { elements: [solution.explanation.computationUnit] },
          solverType: NodeType.COMPUTATION,
        },
      ];
    case "KNOWLEDGE":
      return [
        {
          id: compoundSolverId,
          executingId: compoundExecutingSolverId,
          solutionId: compoundSolutionId,
          parentSolutionId: parentSolutionId,
          ulConclusion: solution.explanation.knowledgePassage,
          solverType: NodeType.KNOWLEDGE,
          source: solution.explanation.source,
        },
      ];
  }
};

export function createGraphStructure(
  questionState: QuestionState
): GraphStructure {
  const validAnswers = filterToValidAnswers(questionState.topLevelAnswers);
  const filterToValidIds = (solutionIds: string[]) => {
    return solutionIds.filter((solutionId) =>
      validAnswers
        .map((answer) => answer.answer.solutionId)
        .includes(solutionId)
    );
  };
  const solvers = new Map(
    [...questionState.solverNodeStates.entries()].map((entry) => {
      const solverNode = entry[1];
      const solverInfo = questionState.solverInfo.get(solverNode.solverId);
      // If the executingSolverId == solverNodeId, cachedSolverQueries == solverNode.childQueryIds
      const cachedSolverQueries =
        solverNode.executingSolverId != null &&
        solverNode.executingSolverId !== solverNode.solverNodeId
          ? questionState.solverNodeStates.get(solverNode.executingSolverId)
              ?.childQueryIds ?? []
          : [];
      const combinedQueries = new Set([
        ...solverNode.childQueryIds,
        ...cachedSolverQueries,
      ]);
      return [
        entry[0],
        {
          childIds: [...combinedQueries],
          id: solverNode.solverNodeId,
          entityType: getSolverType(solverInfo),
          naturalLanguage: null,
          numSolutions: filterToValidIds(solverNode.solutionIds).length,
          parentIds: solverNode.parentQueryIds,
          val: 10,
        } as NodeWithNeighbours,
      ];
    })
  );
  const queries = new Map(
    [...questionState.queryStates.entries()].map((entry) => {
      const queryNode = entry[1];
      return [
        queryNode.queryId,
        {
          childIds: queryNode.solverNodeIds,
          id: queryNode.queryId,
          entityType: "QUERY",
          ul: queryNode.ulQueryAsQuestion, // Use question formatting for query nodes
          naturalLanguage: null,
          numSolutions: filterToValidIds(queryNode.solutionIds).length,
          parentIds: queryNode.parentSolverNodeIds,
          val: 10,
        } as NodeWithNeighbours,
      ];
    })
  );
  // Deal with the root node(s)
  const rootNodes = [...queries.values()].filter(
    (queryNode) => queryNode.parentIds.length === 0
  );
  if (rootNodes.length > 1) {
    const newRootNode = {
      id: ROOT_ID,
      parentIds: [] as string[],
      childIds: rootNodes.map((node) => node.id),
      entityType: "QUERY",
    } as NodeWithNeighbours;
    queries.set(ROOT_ID, newRootNode);
  } else {
    const rootNode = rootNodes[0];
    queries.set(ROOT_ID, { ...rootNode, id: ROOT_ID });
    queries.delete(rootNode.id);
  }
  return {
    solverNodes: solvers,
    queryNodes: queries,
  };
}

function getSolverType(solverInfo: SolverInfo | undefined): NodeType {
  if (
    solverInfo?.name.includes("knowledge solver") ||
    solverInfo?.name.includes("passage store solver")
  ) {
    return NodeType.KNOWLEDGE;
  }
  switch (solverInfo?.name) {
    case "Backward chaining solver":
    case "Hypothetical reasoning solver":
    case "Constrained unknowns solver":
      return NodeType.DEDUCTION;
    case "And solver":
      return NodeType.AND_SOLVER;
    case "Or solver":
      return NodeType.OR_SOLVER;
    case "Computation unit solver":
      return NodeType.COMPUTATION;
  }
  return NodeType.OTHER_SOLVER;
}
