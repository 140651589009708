export enum DomainStatus {
  /** Successfully generated understanding and ran scenarios */
  SUCCESS = "SUCCESS",
  /** An error occurred which could not be handled */
  ERROR = "ERROR",
  GENERATING_UNDERSTANDING = "GENERATING_UNDERSTANDING",
  FAILED_UNDERSTANDING = "FAILED_UNDERSTANDING",
  RUNNING_TRAINING_SCENARIOS = "RUNNING_TRAINING_SCENARIOS",
  FAILED_TRAINING_SCENARIOS = "FAILED_TRAINING_SCENARIOS",
}
