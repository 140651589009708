import { SecondaryButton } from "@dip/ui/components/buttons";
import { Tabs, TabsProps } from "@dip/ui/components/tabs";
import styled from "@emotion/styled";
import { ChevronDoubleRightIcon } from "@heroicons/react/24/solid";
import { GraphAndExplanation } from "@unlikelyai-magic/core";
import { Icon } from "@unlikelyai-magic/ui/icons";
import { useReasoningGraph } from "../../../hooks";

const TabContainer = styled(Tabs)`
  border: 0.0625rem solid ${({ theme }) => theme.colors.component.border[2]};
  border-radius: 0.625rem;
  margin: 1rem;
  flex: 1;
  overflow: hidden;

  .ant-tabs-nav-operations {
    display: none !important;
  }

  .ant-tabs-nav {
    background-color: ${({ theme }) => theme.colors.background.default};
    margin: 0;
  }

  .ant-tabs-tab {
    margin: 0;
  }

  .ant-tabs-tab-btn {
    padding: 0 1rem;
  }

  .ant-tabs-content-holder {
    background-color: ${({ theme }) => theme.colors.component.background[2]};
    display: flex;
  }

  .ant-tabs {
    height: 100%;
  }

  .ant-tabs-tabpane {
    height: 100%;
  }
`;

const CloseWindowButton = styled(SecondaryButton)`
  width: 2rem !important;
  height: 2rem;
  padding: 0;
  border: none;
  margin-right: 0.375rem;
`;

const CloseWindowButtonIcon = styled(Icon)`
  font-size: 1.1112rem;
`;

export const ReasoningGraphTabs = () => {
  const { activeKey, setActiveKey, graphs } = useReasoningGraph();

  const tabItems = graphs.reduce<TabsProps["items"]>(
    (acc, graph, i) =>
      graph.isVisible
        ? [
            ...(acc ?? []),
            {
              key: graph.id,
              /*  TODO: Use a LLM to generate a short summary of the question for the tab label
              See https://linear.app/unlikelyai/issue/UA-459/use-llm-to-generate-question-label-for-the-reasoning-graph-tab. */
              label: `Question ${i + 1}`,
              children: (
                <GraphAndExplanation {...graph} showExplanationCard={false} />
              ),
            },
          ]
        : acc,
    []
  );

  return (
    <TabContainer
      activeKey={activeKey}
      onChange={setActiveKey}
      items={tabItems}
      tabBarExtraContent={{
        right: (
          <CloseWindowButton
            icon={<CloseWindowButtonIcon icon={ChevronDoubleRightIcon} />}
            onClick={() => setActiveKey(undefined)}
          />
        ),
      }}
    />
  );
};
