import styled from "@emotion/styled";
import { TextBox, TextBoxWrapper } from "./TextBox";
import { TextBoxContentProps } from "./TextBoxContent";

const CodeBoxHeader = styled(TextBox.Header)`
  color: ${({ theme }) => theme.colors.text.primary.default};
  background-color: ${({ theme }) => theme.colors.background.default};
  padding: 0.75rem 1rem;
  border-bottom: 0.0625rem solid
    ${({ theme }) => theme.colors.component.border[1]};
`;

const CodeBoxContent = styled(TextBox.Content)`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacings.lg};
  color: ${({ theme }) => theme.colors.action.dark.hover};
  background-color: ${({ theme }) => theme.colors.component.background[1]};
  font-family: monospace;
  padding-top: 1rem;
`;

const CodeTextBoxWrapper = styled(TextBoxWrapper)`
  background-color: ${({ theme }) => theme.colors.component.background[1]};
  border-radius: 0;
  box-shadow: none;
  height: 100%;
`;

export type CodeTextBoxProps = TextBoxContentProps & {
  header?: string;
};

export const CodeTextBox = ({
  value,
  header,
  readOnly,
  onSelectionChange,
}: CodeTextBoxProps) => (
  <CodeTextBoxWrapper>
    {header && <CodeBoxHeader>{header}</CodeBoxHeader>}
    <CodeBoxContent
      readOnly={readOnly}
      value={value}
      onSelectionChange={onSelectionChange}
    />
  </CodeTextBoxWrapper>
);
