// An indicator used in the Ul editor to indicate that all passage stores should be used for a given function
import { ALL_LEGACY_AND_NON_DEFAULT_STORE_URIS } from "../stores/stores";

const ALL_PASSAGE_STORES_INDICATOR = "*";

/**
 * Build the store URIs to query an endpoint for the given store URI.
 */
export function buildStoreUris(storeUri?: string): string[] {
  // if there is no store URI, we want to use all stores
  if (!storeUri) {
    return [ALL_PASSAGE_STORES_INDICATOR];
  }

  const matchesWildcard = ALL_LEGACY_AND_NON_DEFAULT_STORE_URIS.some(
    (uriPattern) => {
      const regex = new RegExp(`^${uriPattern.replace(/\*/g, ".*")}$`);
      return regex.test(storeUri);
    }
  );
  // If the store URI is in the legacy stores list, leave blank for backwards compatibility
  // existing backend behaviour is to use the legacy stores by default
  if (matchesWildcard) {
    return [];
  }

  // otherwise, the store URI is a specific store URI we want to use
  return [storeUri];
}
