import { useContext } from "react";
import { ReasoningGraphContext } from "../providers";

export const useReasoningGraph = () => {
  const context = useContext(ReasoningGraphContext);
  if (!Object.keys(context).length)
    throw new Error(
      "useReasoningGraph must be used within a ReasoningGraphContext"
    );
  return context;
};
