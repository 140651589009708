import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import styled from "@emotion/styled";
import { Card, Layout, Spin } from "antd";
import { NicknameDataContext } from "../../nicknames/NicknameDataContext";
import { PassageSourceInformationDataContext } from "../../providers";
import { AnswerVM } from "../../reasoning-engine/reasoning-engine";
import { FinalQuestionState } from "../../reasoning-engine/states/final-question-state";
import { TranslationDataContext } from "../../translation/TranslationDataContext";
import {
  ExplanationAndIds,
  REExplanationTranslationResponse,
} from "../../translation/translator/translator";
import { gatherAllUuids } from "../../ul/UlSerializer";
import { SourceInformation } from "../SourcedExplanation.types";
import {
  SelectableExplanationWithSources,
  SolutionIdToSource,
} from "../explanation";
import { StarsIcon } from "./StarsIcon";
import { solverPathAndDetailsFrom } from "./create-graph-data";
import { Graph } from "./graph";
import { findDisjointNodes, orderSubtreesLeafToRoot } from "./graph-traversal";
import { NodeType, SolverDetails } from "./reasoning-graph-types";

function normaliseText(text: string) {
  return text.replace(/[^a-zA-Z]/g, "").toLowerCase();
}

export const ExplanationCard = styled(Card)`
  width: 50%;
  max-height: 80%;
  overflow: auto;
  position: absolute;
  top: 35px;
  left: 2%;
  z-index: 10;
  background-color: rgba(255, 248, 248, 0.35);
`;

export interface GraphAndExplanationProps {
  finalQuestionState: FinalQuestionState;
  answer: AnswerVM;
  question?: string;
  translatedExplanation: REExplanationTranslationResponse;
  selectedExplanation?: ExplanationAndIds;
  setSelectedExplanation?: (
    selectedExplanation: ExplanationAndIds | undefined
  ) => void;
  hoveredExplanation?: ExplanationAndIds;
  setHoveredExplanation?: Dispatch<
    SetStateAction<ExplanationAndIds | undefined>
  >;
  showUlInitial?: boolean;
  showExplanationCard?: boolean;
}

/**
 * This component is responsible for rendering the graph for a solution and allowing the user to interact via the explanation for that solution.
 */
export const GraphAndExplanation = (props: GraphAndExplanationProps) => {
  const {
    finalQuestionState,
    answer,
    question,
    translatedExplanation,
    selectedExplanation,
    setSelectedExplanation,
    hoveredExplanation: externalHoveredExplanation,
    setHoveredExplanation: setExternalHoveredExplanation,
    showUlInitial = false,
    showExplanationCard = true,
  } = props;
  const { notifyUnknownUL, ulToNatLang } = useContext(TranslationDataContext);
  const { passageToSources, notifyUnknownPassageSource } = useContext(
    PassageSourceInformationDataContext
  );
  const { uuidToNicknameMap, notifyUnknownUuids } =
    useContext(NicknameDataContext);

  const answerPath = useMemo(() => {
    return new Set(
      solverPathAndDetailsFrom(answer, finalQuestionState.allSolutions)
    );
  }, [answer, finalQuestionState]);

  useEffect(() => {
    const newNodesFromAnswerPath = [...answerPath.values()].flatMap(
      (solverDetails) => [
        ...gatherAllUuids(solverDetails.ulConclusion ?? { elements: [] }),
      ]
    );
    const unknownUuids = [...newNodesFromAnswerPath].filter(
      (uuid) => !uuidToNicknameMap.has(uuid)
    );
    if (unknownUuids.length) notifyUnknownUuids(unknownUuids);
  }, [answerPath, uuidToNicknameMap, notifyUnknownUuids]);

  const [internalHoveredExplanation, setInternalHoveredExplanation] = useState<
    ExplanationAndIds | undefined
  >();
  const [simpleSourceIdToUrl, setSimpleSourceIdToUrl] = useState<
    Map<string, string[]>
  >(new Map());
  const [solutionIdToSimpleId, setSolutionIdToSimpleId] = useState<
    Map<string, string>
  >(new Map());

  const solutionIdsToSources: SolutionIdToSource[] | undefined = useMemo(() => {
    return [...answerPath].reduce(
      (acc: SolutionIdToSource[], solverDetails: SolverDetails) => {
        if (solverDetails.solverType === NodeType.KNOWLEDGE) {
          const knowledgePassage = solverDetails.ulConclusion;
          const sources = passageToSources.get(
            JSON.stringify(knowledgePassage)
          );
          if (sources?.length) {
            acc.push({
              solutionId: solverDetails.solutionId,
              source: sources[0],
            });
          }
        }
        return acc;
      },
      []
    );
  }, [
    answerPath,
    translatedExplanation.summarisedTranslation,
    passageToSources,
  ]);

  useEffect(() => {
    if (!solutionIdsToSources) {
      return;
    }

    const explanationSolutionIdsToSources = solutionIdsToSources.filter(
      (sourceIdToSourceInfo) =>
        translatedExplanation.summarisedTranslation.some((explanation) =>
          explanation.solutionIds.includes(sourceIdToSourceInfo.solutionId)
        )
    );
    // Convert solution UUIDs to simple integers, suitable for showing in the UI
    const solutionIdToSimpleId = new Map<string, string>(
      explanationSolutionIdsToSources.map((sourceIdToSourceInfo, index) => [
        sourceIdToSourceInfo.solutionId,
        `${index + 1}`,
      ])
    );

    setSolutionIdToSimpleId(solutionIdToSimpleId);

    const simpleIdsToSourceDictionary: Map<string, SourceInformation> = new Map<
      string,
      SourceInformation
    >(
      explanationSolutionIdsToSources
        .filter((sourceIdToSourceInfo) =>
          solutionIdToSimpleId.has(sourceIdToSourceInfo.solutionId)
        )
        .map((sourceIdToSourceInfo) => [
          solutionIdToSimpleId.get(sourceIdToSourceInfo.solutionId) as string,
          sourceIdToSourceInfo.source,
        ])
    );

    const formatSources = (sources: Map<string, SourceInformation>) => {
      const sourceMap = new Map<string, string[]>();
      Array.from(sources.entries()).forEach(([key, value]) => {
        function constructSourceArray(
          source: SourceInformation,
          sourceList: string[]
        ): string[] {
          const formattedSource = source.url.replaceAll('"', "");
          sourceList.push(formattedSource);
          if (!source.children || source.children.length === 0) {
            return sourceList;
          }
          // Note: we only take the first child to avoid large branches
          return constructSourceArray(source.children[0], sourceList);
        }
        // Reverse the source array so that we get "child source, parent source" etc.
        sourceMap.set(key, constructSourceArray(value, []).reverse());
      });
      return sourceMap;
    };

    const simpleSourceIdsToFormattedUrl = formatSources(
      simpleIdsToSourceDictionary
    );
    setSimpleSourceIdToUrl(simpleSourceIdsToFormattedUrl);
  }, [solutionIdsToSources]);

  const hoveredExplanation =
    externalHoveredExplanation ?? internalHoveredExplanation;

  const setHoveredExplanation =
    setExternalHoveredExplanation ?? setInternalHoveredExplanation;

  const solutionTree = useMemo(() => {
    const solutionTree = new Map<string, Set<string>>();
    answerPath.forEach((solverDetails) => {
      if (solverDetails.parentSolutionId) {
        solutionTree.set(
          solverDetails.parentSolutionId,
          (solutionTree.get(solverDetails.parentSolutionId) ?? new Set()).add(
            solverDetails.solutionId
          )
        );
      }
    });
    return solutionTree;
  }, [answerPath]);

  const labelsBySolutionId = useMemo(() => {
    const labelsFromExplanation = new Map(
      translatedExplanation.fullTranslation?.flatMap((explanation) => {
        if (!explanation.solutionIds) return [];
        return explanation.solutionIds.map((id) => {
          // Any step that starts with 'Therefore, ...' does not make grammatical sense, so strip it
          // from the text - leave other occurrences however as they do make sense - e.g.
          // "I know that X therefore, Y" is fine, but a statement of "Therefore Y" is not.
          // Also, ensure any words that are not meant to be capitalised are replaced with their lowercase
          const uncapitalisedWordsPattern = new RegExp(
            `\\b(${UNCAPITALIZED_WORDS.join("|")})\\b`,
            "gi"
          );
          const nlTextWithoutTherefore = explanation.explanation
            .trim()
            .replace(/^Therefore, /, "")
            .replace(uncapitalisedWordsPattern, (match) => match.toLowerCase());

          return [id, nlTextWithoutTherefore];
        });
      }) ?? []
    );
    const andSolverLabels: [string, string][] = [...answerPath]
      .filter(
        (solverDetails) =>
          solverDetails.solverType === NodeType.AND_SOLVER ||
          solverDetails.solverType === NodeType.OR_SOLVER
      )
      .map((solverDetails) => {
        const children = [...answerPath].filter(
          (solver) => solver.parentSolutionId === solverDetails.solutionId
        );
        const cleanChildren = children
          .map((child) => labelsFromExplanation.get(child.solutionId))
          .filter((childLabel) => childLabel)
          .map((childLabel) => {
            const clean =
              childLabel?.replace(new RegExp("\\.$"), "").trim() ?? "";

            return (
              (CAPITALIZED_WORDS.some(
                (word) =>
                  normaliseText(word) === normaliseText(clean.split(" ")[0])
              )
                ? clean[0]
                : clean[0].toLowerCase()) + clean.slice(1)
            );
          })
          .join(" and ");
        return [
          solverDetails.solutionId,
          cleanChildren.length
            ? cleanChildren[0].toUpperCase() + cleanChildren.slice(1) + "."
            : "",
        ];
      });
    return new Map([...labelsFromExplanation, ...andSolverLabels]);
  }, [answerPath, translatedExplanation.fullTranslation]);

  const summarisedExplanationSubgraphs: ExplanationAndIds[] = useMemo(() => {
    return translatedExplanation.summarisedTranslation.map((explanation) => {
      const idsDeduplicatedByLabel = explanation.solutionIds.reduce(
        (acc: string[], solutionId: string) => {
          const label = labelsBySolutionId.get(solutionId);
          const existingLabels = acc.map((id) => labelsBySolutionId.get(id));
          if (!label || existingLabels.includes(label)) {
            return acc;
          }
          return [...acc, solutionId];
        },
        []
      );
      const chosenNodes = findDisjointNodes(
        idsDeduplicatedByLabel,
        solutionTree
      );
      return {
        explanation: explanation.explanation,
        solutionIds: chosenNodes,
      };
    });
  }, [
    translatedExplanation.summarisedTranslation,
    solutionTree,
    labelsBySolutionId,
  ]);

  const explanationSteps = useMemo(() => {
    const stepIds =
      summarisedExplanationSubgraphs?.flatMap(
        (explanationAndIds) => explanationAndIds.solutionIds
      ) ?? [];
    const ordered = orderSubtreesLeafToRoot(stepIds, solutionTree);
    // Deduplicate steps by explanation string
    return ordered.reduce((acc, solutionId) => {
      const explanation = labelsBySolutionId.get(solutionId);
      if (
        acc.some(
          (explanationAndIds) => explanationAndIds.explanation === explanation
        )
      ) {
        return acc;
      }

      acc.push({
        explanation: explanation ?? "",
        solutionIds: [solutionId],
      });
      return acc;
    }, [] as ExplanationAndIds[]);
  }, [labelsBySolutionId, solutionTree, summarisedExplanationSubgraphs]);

  const nodeIdsBySolutionId = useMemo(() => {
    const solverNodeBySolutionId: Map<string, string[]> = new Map();
    [...answerPath].forEach((solverDetails) => {
      const solutionSolvers =
        solverNodeBySolutionId.get(solverDetails.solutionId) ?? [];
      solverNodeBySolutionId.set(solverDetails.solutionId, [
        solverDetails.id,
        ...solutionSolvers,
      ]);
    });
    const allNodesBySolutionId = new Map([...solverNodeBySolutionId]);
    return { allNodesBySolutionId, solverNodeBySolutionId };
  }, [answerPath]);

  useEffect(() => {
    const queries = [...answerPath].flatMap((solverDetails) => {
      return (
        nodeIdsBySolutionId.allNodesBySolutionId
          .get(solverDetails.solutionId)
          ?.flatMap((nodeId) => {
            const queryNode =
              finalQuestionState.graphStructure.queryNodes.get(nodeId);
            return queryNode && queryNode.ul ? [queryNode.ul] : [];
          }) ?? []
      );
    });
    const rootQuery =
      finalQuestionState.graphStructure.queryNodes.get("root")?.ul;
    if (question) {
      ulToNatLang.set(JSON.stringify(rootQuery), question);
    } else if (rootQuery) {
      notifyUnknownUL([rootQuery]);
    }
    notifyUnknownUL(queries);
  }, [
    answerPath,
    finalQuestionState.graphStructure.queryNodes,
    nodeIdsBySolutionId.allNodesBySolutionId,
    notifyUnknownUL,
    question,
    ulToNatLang,
  ]);

  const hoveredNodeIds: Set<string> = useMemo(() => {
    if (hoveredExplanation) {
      return new Set(
        hoveredExplanation.solutionIds.flatMap((solutionId) => {
          return nodeIdsBySolutionId.allNodesBySolutionId.get(solutionId) ?? [];
        })
      );
    }
    return new Set();
  }, [hoveredExplanation, nodeIdsBySolutionId.allNodesBySolutionId]);

  const selectedNodeIds: Set<string> = useMemo(() => {
    if (selectedExplanation) {
      return new Set(
        selectedExplanation.solutionIds.flatMap((solutionId) => {
          const solverNodesIds =
            nodeIdsBySolutionId.allNodesBySolutionId.get(solutionId);
          if (!solverNodesIds) {
            console.error("missing solver id for: ", solutionId);
          }
          return solverNodesIds ?? [];
        })
      );
    }
    return new Set();
  }, [nodeIdsBySolutionId.allNodesBySolutionId, selectedExplanation]);

  const labelsByNodeId = useMemo(() => {
    return new Map(
      [...labelsBySolutionId].flatMap(([solutionId, label]) => {
        return (
          nodeIdsBySolutionId.solverNodeBySolutionId
            .get(solutionId)
            ?.map((nodeId) => {
              return [nodeId, label];
            }) ?? []
        );
      })
    );
  }, [labelsBySolutionId, nodeIdsBySolutionId]);

  const sourcesByNodeId = useMemo(() => {
    return Array.from(answerPath).reduce((sourcesMap, solverDetails) => {
      if (solverDetails.solverType === NodeType.KNOWLEDGE) {
        // Get both the reasoning engine source and the full passage source info as returned from
        // lookup and combine into the one object to be used by the graph
        const reSource = solverDetails.source;
        const passageSourceInfo = passageToSources.get(
          JSON.stringify(solverDetails.ulConclusion)
        );

        if (passageSourceInfo && reSource) {
          sourcesMap.set(solverDetails.id, {
            solverSource: reSource,
            sourceInformation: passageSourceInfo,
          });
        } else {
          notifyUnknownPassageSource(solverDetails.ulConclusion);
        }
      } else {
        sourcesMap.set(solverDetails.id, {});
      }

      return sourcesMap;
    }, new Map());
  }, [answerPath, notifyUnknownPassageSource, passageToSources]);

  const onExplanationSelection = (
    explanation: ExplanationAndIds | undefined
  ) => {
    if (!setSelectedExplanation) {
      return;
    }
    if (
      selectedExplanation &&
      selectedExplanation.explanation === explanation?.explanation
    ) {
      setSelectedExplanation(undefined);
    } else {
      setSelectedExplanation(explanation);
    }
  };

  const clearSelection = () => {
    if (setSelectedExplanation) {
      setSelectedExplanation(undefined);
    }
  };

  return (
    <Layout>
      {showExplanationCard && (
        <ExplanationCard>
          {summarisedExplanationSubgraphs ? (
            <div style={{ display: "flex" }}>
              <span style={{ marginRight: 8, marginTop: 3 }}>
                <StarsIcon />
              </span>
              <div data-cy="graph-explanation">
                <SelectableExplanationWithSources
                  solutionIdToSourceId={solutionIdToSimpleId}
                  summarisedExplanation={summarisedExplanationSubgraphs}
                  explanationSteps={explanationSteps}
                  sources={simpleSourceIdToUrl}
                  selectedExplanation={selectedExplanation}
                  onExplanationSelection={onExplanationSelection}
                  onExplanationHovered={setHoveredExplanation}
                  // This works horribly right now, so we disable it
                  runExplanationAnimation={false}
                />
              </div>
            </div>
          ) : (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Spin />
            </div>
          )}
        </ExplanationCard>
      )}
      <Graph
        graphStructure={finalQuestionState.graphStructure}
        labelsByNodeId={labelsByNodeId}
        hoveredNodeIds={hoveredNodeIds}
        selectedNodeIds={selectedNodeIds}
        sourcesByNodeId={sourcesByNodeId}
        selectedSolvers={answerPath}
        queryNodeLabels={ulToNatLang}
        clearSelection={clearSelection}
        showUlInitial={showUlInitial}
      />
    </Layout>
  );
};

// List to make demos graph labels look nice
const CAPITALIZED_WORDS = [
  "I",
  "William",
  "Dundee",
  "Cambridge",
  "Tunstall-Pedoe",
  "Amazon",
  "Bloomberg",
  "London",
  "Evi",
  "QA",
  "GIMP",
  "Dutch",
  "Frank",
  "San",
  "Bruce",
];

// List to make demos graph labels look nice (Ensuring these words are kept lowercase)
const UNCAPITALIZED_WORDS = ["someone"];
