export const CORE_STORE_URI = "ai.unlikely.core";
export const COMMON_STORE_URI = "ai.unlikely.common";
export const LEGACY_STORE_URIS = [
  CORE_STORE_URI,
  "ai.unlikely.debaffling",
  "ai.unlikely.thinkingresults",
  "ai.unlikely.wordnet",
  "ai.unlikely.wordnet-reconcept",
  "ai.unlikely.wikidata",
  "ai.unlikely.wikidata-reconcept",
  "ai.unlikely.prereasoning",
  "ai.unlikely.web-sources.*",
];

export const JOBE_STORES = [
  "ai.unlikely.jobe.main.*",
  "ai.unlikely.jobe.scratch.*",
];
export const JOBE_LE_PLAY_STORE = ["ai.unlikely.jobe.le-play.*"];
export const OPEN_API_STORE = ["ai.unlikely.openapi"];
export const ULTRRA_EXPERIMENTS_STORE = ["ai.unlikely.ultrra-experiments"];
// TODO(PLAT-2811): Make this pull the stores from the backend
export const EXPERIMENTS_STORES = [
  "ai.unlikely.experiments.*",
  "ai.unlikely.experiments.sneese",
];

export const NON_DEFAULT_STORES = [
  ...JOBE_STORES,
  ...JOBE_LE_PLAY_STORE,
  ...OPEN_API_STORE,
  ...ULTRRA_EXPERIMENTS_STORE,
  ...EXPERIMENTS_STORES,
];

export const CORE_DATA_STORES = [
  "ai.unlikely.foundations",
  COMMON_STORE_URI,
  "ai.unlikely.curated",
  "ai.unlikely.observations.sumo.*",
  "ai.unlikely.observations.yago.*",
];

export const ALL_LEGACY_AND_NON_DEFAULT_STORE_URIS = [
  ...LEGACY_STORE_URIS,
  ...NON_DEFAULT_STORES,
];

export const ALL_STORE_URIS = [
  ...LEGACY_STORE_URIS,
  ...NON_DEFAULT_STORES,
  ...CORE_DATA_STORES,
];
