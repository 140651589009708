import { ComponentProps } from "react";
import styled from "@emotion/styled";

export type PromptInputHeaderProps = ComponentProps<"div">;

export const PromptInputHeader = styled((props: PromptInputHeaderProps) => {
  return <div {...props} />;
})`
  background: ${({ theme }) => theme.colors.component.background[1]};
  border: 0.0625rem solid ${({ theme }) => theme.colors.component.border[1]};
  border-radius: ${({ theme }) => theme.roundness.md};
  padding: ${({ theme }) => theme.spacings.lg};

  // Prevent header icons from flex shrinking
  svg {
    flex-shrink: 0;
  }
`;
