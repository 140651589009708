import React, {
  FunctionComponent,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import {
  NicknameData,
  NicknameDataContext,
} from "../nicknames/NicknameDataContext";
import { useNicknameDomain } from "../nicknames/NicknameDomainContext";
import { NicknameType } from "../nicknames/nickname-domain";
import {
  DefinedNicknamePair,
  NicknamePair,
  getUsedNickname,
  isPseudoNicknamePair,
} from "../nicknames/nickname-pair";
import { useCognitoToken } from "../providers";
import { UlElement, isUlPassage, isUlString, isUlUuid } from "./ul-element";

type UlSerializerContextProps = {
  children: React.ReactNode;
};

export const UlSerializerContext: FunctionComponent<
  UlSerializerContextProps
> = (props) => {
  const nicknameDomain = useNicknameDomain();
  const { token, setToken } = useCognitoToken();

  const [nicknameData, setNicknameData] = useState<NicknameData>({
    uuidToNicknameMap: new Map(),
    uuidToTemporaryNickname: new Map(),
    notifyUnknownUuids,
    setTemporaryNicknames,
    clearNicknameCache,
  });

  const processingUuids = useRef(false);
  const uuidsBeingProcessed = useRef<Set<string>>(new Set());
  const uuidsToProcess = useRef<Set<string>>(new Set());

  function setTemporaryNicknames(
    updatedNicknames: Map<string, DefinedNicknamePair>
  ) {
    setNicknameData((prevState) => {
      return {
        ...prevState,
        uuidToTemporaryNickname: updatedNicknames,
      };
    });
  }

  // If the nickname domain changes then reset the uuid to nickname cache
  useEffect(clearNicknameCache, [nicknameDomain]);

  function clearNicknameCache() {
    uuidsBeingProcessed.current.clear();
    uuidsToProcess.current.clear();
    setNicknameData((prevState) => ({
      ...prevState,
      uuidToNicknameMap: new Map(),
    }));
  }

  function notifyUnknownUuids(
    unknownUuids: string[],
    storeUri?: string,
    onlyRealNicknames?: boolean
  ) {
    // Check these uuids are not already being processed
    // otherwise set them to be processed
    unknownUuids.forEach((uuid) => {
      if (!uuidsBeingProcessed.current.has(uuid)) {
        uuidsToProcess.current.add(uuid);
      }
    });

    tryProcessUuids(storeUri, onlyRealNicknames);
  }

  function tryProcessUuids(storeUri?: string, onlyRealNicknames?: boolean) {
    // This is a lock that ensures only
    // one request for nicknames can happen at once
    if (processingUuids.current || uuidsToProcess.current.size === 0) return;
    processingUuids.current = true;

    // Limit nicknames to fetch in a single batch to 100
    const allUuidsToProcessArray = Array.from(uuidsToProcess.current);
    const uuidsToProcessArray = allUuidsToProcessArray.slice(0, 100);
    const remainingUuidsToProcess = allUuidsToProcessArray.slice(100);

    uuidsBeingProcessed.current = new Set(uuidsToProcessArray);
    uuidsToProcess.current = new Set(remainingUuidsToProcess);

    const nicknameType = onlyRealNicknames ? NicknameType.PROPER : undefined;

    nicknameDomain
      .getNicknames(uuidsToProcessArray, storeUri, nicknameType, token)
      .then((nicknamePairs: NicknamePair[]) =>
        setNicknameData((prevState) => {
          const newMap = prevState.uuidToNicknameMap;

          // First ensure all uuids have an entry by adding an empty one
          uuidsToProcessArray.forEach((uuid) => newMap.set(uuid, undefined));
          // Then add the true result for any that have a known nickname
          nicknamePairs.forEach((pair) => newMap.set(pair.uuid, pair));

          return { ...prevState, uuidToNicknameMap: newMap };
        })
      )
      .catch((err) => console.error(err))
      .finally(() => {
        // Release the lock
        processingUuids.current = false;

        // Try and process uuids again as there may have been
        // new uuids added while this request was processing
        tryProcessUuids(storeUri, onlyRealNicknames);
      });
  }

  return (
    <NicknameDataContext.Provider value={nicknameData}>
      {props.children}
    </NicknameDataContext.Provider>
  );
};

type UlSerializerProps = {
  element: UlElement;
  truncateUuids?: boolean;
  onlyRealNicknames?: boolean;
  children: (ulString: string) => React.ReactElement;
  storeUri?: string;
};

export const UlSerializer: FunctionComponent<UlSerializerProps> = (props) => {
  const { uuidToNicknameMap, uuidToTemporaryNickname, notifyUnknownUuids } =
    useContext(NicknameDataContext);

  useEffect(() => {
    const uuids = Array.from(gatherAllUuids(props.element));
    const unknownUuids = uuids.filter((uuid) => !uuidToNicknameMap.has(uuid));
    if (unknownUuids.length > 0)
      notifyUnknownUuids(unknownUuids, props.storeUri, props.onlyRealNicknames);
  });

  const ulString = serialiseUlElement(
    props.element,
    new Map([...uuidToNicknameMap, ...uuidToTemporaryNickname]),
    props.onlyRealNicknames !== true,
    props.truncateUuids === true
  );
  return props.children(ulString);
};

export function gatherAllUuids(element: UlElement): Set<string> {
  if (isUlPassage(element)) {
    const uuids = new Set<string>();
    element.elements.forEach((ulElement) =>
      gatherAllUuids(ulElement).forEach((uuid) => uuids.add(uuid))
    );
    return uuids;
  }

  if (isUlUuid(element)) {
    return new Set<string>().add(element.uuid);
  }

  return new Set();
}

export function serialiseUlElement(
  element: UlElement,
  nicknameMap: Map<string, NicknamePair | undefined>,
  usePseudoNicknames: boolean,
  truncateUuids: boolean
): string {
  if (isUlPassage(element)) {
    return (
      "(" +
      element.elements
        .map((elem) =>
          serialiseUlElement(
            elem,
            nicknameMap,
            usePseudoNicknames,
            truncateUuids
          )
        )
        .join(" ") +
      ")"
    );
  } else if (isUlString(element)) {
    return `"${element}"`;
  } else {
    const nicknamePair = nicknameMap.get(element.uuid);
    if (
      !nicknamePair ||
      (!usePseudoNicknames && isPseudoNicknamePair(nicknamePair))
    ) {
      return truncateUuids ? element.uuid.substr(0, 8) : element.uuid;
    }
    return getUsedNickname(nicknamePair);
  }
}
