import { LoadingOutlined } from "@ant-design/icons";
import styled from "@emotion/styled";
import { Alert, Modal, Spin } from "antd";
import { GraphAndExplanation } from ".";
import { AnswerVM } from "../../reasoning-engine/reasoning-engine";
import { FinalQuestionState } from "../../reasoning-engine/states/final-question-state";
import { REExplanationTranslationResponse } from "../../translation/translator/translator";
import { CentredDiv } from "./styles";

const DemoModal = styled(Modal)`
  .ant-modal-body {
    height: calc(100vh - 250px);
    overflow: auto;
    padding: 10px;
  }
`;

type GraphModalProps = {
  finalQuestionState?: FinalQuestionState;
  answer: AnswerVM;
  isOpen: boolean;
  close: () => void;
  translatedExplanation?: REExplanationTranslationResponse;
  isTranslationLoading?: boolean;
  className?: string;
};

export const GraphModal = ({
  finalQuestionState,
  answer,
  isOpen,
  close,
  translatedExplanation,
  isTranslationLoading = false,
  className,
}: GraphModalProps) => {
  const ConditionalGraphModalComponents = () => {
    const LoadingOrFailure = () => {
      if (isTranslationLoading) {
        return (
          <>
            <Spin
              indicator={<LoadingOutlined spin />}
              size="large"
              style={{ margin: "auto" }}
            />
          </>
        );
      } else {
        return (
          <>
            <CentredDiv>
              <Alert
                message="Failed to load translation for this graph"
                type="error"
                showIcon
              />
            </CentredDiv>
          </>
        );
      }
    };

    if (!translatedExplanation) {
      return <LoadingOrFailure />;
    }

    if (!finalQuestionState) {
      // Spin for a while until the full question state (RE graph) is loaded
      return (
        <>
          <Spin
            indicator={<LoadingOutlined spin />}
            size="large"
            style={{ margin: "auto" }}
          />
        </>
      );
    }

    return (
      <>
        <GraphAndExplanation
          finalQuestionState={finalQuestionState!}
          answer={answer}
          translatedExplanation={translatedExplanation}
        />
      </>
    );
  };

  return (
    <DemoModal
      open={isOpen}
      footer={null}
      onCancel={close}
      cancelButtonProps={{ style: { display: "none" } }}
      width={"80%"}
      className={className}
    >
      <ConditionalGraphModalComponents />
    </DemoModal>
  );
};
