import React, { useCallback, useEffect, useMemo, useState } from "react";
import { DownOutlined, UpOutlined } from "@ant-design/icons";
import clsx from "clsx";
import { ExplanationAndIds } from "../../../translation/translator/translator";
import { SourceLinks } from "./SourceLinks";
import { CLOSING_SOURCE_BRACKET, OPENING_SOURCE_BRACKET } from "./consts";
import "./styles.css";
import { SourcedExplanationWithIds } from "./types";
import { getSourcedExplanation } from "./utils";

type SelectableExplanationWithSourcesProps = {
  summarisedExplanation: ExplanationAndIds[];
  explanationSteps?: ExplanationAndIds[];
  solutionIdToSourceId?: Map<string, string>;
  sources?: Map<string, string[]>;
  selectedExplanation?: ExplanationAndIds;
  onExplanationSelection?: (explanation: ExplanationAndIds | undefined) => void;
  onExplanationHovered?: (explanation: ExplanationAndIds | undefined) => void;
  runExplanationAnimation?: boolean;
};

const ANIMATION_WAIT_TIME_MS = 1100;
const ANIMATION_INITIAL_WAIT_TIME_MS = 1000;

/**
 * Represents a summarised explanation, optionally with sourcing information and selectable explanations
 *
 * @component
 * @param summarisedExplanation - the summarised explanation to be represented
 * @param solutionIdToSourceId - a map of the solution ids in the explanation to their source ids
 * @param sources - a map of the source ids to their source links
 * @param selectedExplanation - the currently selected explanation
 * @param onExplanationSelection - callback for when an explanation is selected
 * @param onExplanationHovered - callback for when an explanation is hovered
 * @param runExplanationAnimation - determines whether to run the explanation animation
 */
export const SelectableExplanationWithSources = ({
  summarisedExplanation,
  explanationSteps,
  solutionIdToSourceId,
  sources,
  selectedExplanation,
  onExplanationSelection,
  onExplanationHovered,
  runExplanationAnimation = false,
}: SelectableExplanationWithSourcesProps) => {
  const [hoveredExplanation, setHoveredExplanation] = useState<
    ExplanationAndIds | undefined
  >();
  const [sourcesLoaded, setSourcesLoaded] = useState(false);

  useEffect(() => {
    if (sources?.size) {
      setSourcesLoaded(true);
    }
  }, [sources]);

  const showSources = !!sources?.size && !!solutionIdToSourceId;
  const allowSelection = onExplanationSelection !== undefined;

  const sourcedExplanationWithIds: SourcedExplanationWithIds[] = useMemo(() => {
    if (!showSources) {
      return [];
    }

    return getSourcedExplanation({
      summarisedExplanation,
      solutionIdToSourceId,
    });
  }, [showSources, solutionIdToSourceId, summarisedExplanation]);

  const onHoverEnter = useCallback(
    (explanation: ExplanationAndIds) => {
      if (!allowSelection) {
        return;
      }
      setHoveredExplanation(explanation);

      if (onExplanationHovered) {
        onExplanationHovered(explanation);
      }
    },
    [allowSelection, onExplanationHovered]
  );

  const onHoverLeave = useCallback(() => {
    if (!allowSelection) {
      return;
    }
    setHoveredExplanation(undefined);

    if (onExplanationHovered) {
      onExplanationHovered(undefined);
    }
  }, [allowSelection, onExplanationHovered]);

  const handleExplanationSelection = (explanation: ExplanationAndIds) => {
    if (!allowSelection) {
      return;
    }
    if (
      selectedExplanation &&
      selectedExplanation.explanation === explanation.explanation
    ) {
      onExplanationSelection(undefined);
    } else {
      onExplanationSelection(explanation);
    }
  };

  const isExplanationHovered = useCallback(
    (explanation: ExplanationAndIds) => {
      if (!allowSelection) {
        return;
      }

      return hoveredExplanation?.explanation === explanation.explanation;
    },
    [allowSelection, hoveredExplanation?.explanation]
  );

  const isExplanationSelected = useCallback(
    (explanation: ExplanationAndIds) => {
      if (!allowSelection) {
        return;
      }
      return selectedExplanation?.explanation === explanation.explanation;
    },
    [allowSelection, selectedExplanation?.explanation]
  );

  const isExplanationInactive = useCallback(
    (explanation: ExplanationAndIds) => {
      if (!allowSelection) {
        return;
      }

      if (!hoveredExplanation && !selectedExplanation) {
        return undefined; // Inactive if neither hovered nor selected.
      }

      const isSelectedOrHovered =
        isExplanationHovered(explanation) || isExplanationSelected(explanation);

      return !isSelectedOrHovered; // Inactive if not hovered or selected.
    },
    [
      allowSelection,
      hoveredExplanation,
      selectedExplanation,
      isExplanationHovered,
      isExplanationSelected,
    ]
  );

  const [showSteps, setShowSteps] = useState(true);

  const runStepsAnimation = () => {
    if (explanationSteps) {
      explanationSteps.forEach((explanation, index) => {
        setTimeout(() => {
          handleExplanationSelection(explanation);
        }, ANIMATION_WAIT_TIME_MS * index + ANIMATION_INITIAL_WAIT_TIME_MS);
      });
      setTimeout(() => {
        onExplanationSelection && onExplanationSelection(undefined);
      }, ANIMATION_WAIT_TIME_MS * explanationSteps.length + ANIMATION_INITIAL_WAIT_TIME_MS);
    }
  };

  /* TODO: runStepsAnimation should be a dep of this effect, but it looks like that could require a fairly large
  refactor this this component (and others) */
  useEffect(() => {
    if (runExplanationAnimation) runStepsAnimation();
  }, [runExplanationAnimation]);

  const ShowStepsButton = () => (
    <span
      style={{ cursor: "pointer" }}
      onClick={() => setShowSteps(!showSteps)}
    >
      {showSteps ? (
        <b>
          Hide steps <UpOutlined />
        </b>
      ) : (
        <b>
          Show steps <DownOutlined />
        </b>
      )}
    </span>
  );

  const ExplanationSteps = () => (
    <div>
      <br />
      <ShowStepsButton />
      {showSteps && <br />}
      {showSteps &&
        explanationSteps &&
        explanationSteps.map((explanationAndId, index) => {
          const cleanedStep = explanationAndId.explanation
            .replace("Therefore, ", "")
            .trim();
          return (
            explanationAndId.explanation && (
              <span
                className={clsx("explanation", {
                  isHovering: hoveredExplanation !== undefined,
                  hovered: isExplanationHovered(explanationAndId),
                  active: isExplanationSelected(explanationAndId),
                  inactive: isExplanationInactive(explanationAndId),
                })}
                onMouseEnter={() => onHoverEnter(explanationAndId)}
                onMouseLeave={onHoverLeave}
                onClick={() => handleExplanationSelection(explanationAndId)}
                key={index}
              >
                {`${index + 1}. ${
                  cleanedStep[0].toUpperCase() + cleanedStep.slice(1)
                }`}
                <br />
              </span>
            )
          );
        })}
    </div>
  );

  if (!showSources) {
    return (
      <div>
        <div data-cy="explanation-content">
          {summarisedExplanation.map((explanationAndId, index) => {
            if (!allowSelection) {
              return <span key={index}>{explanationAndId.explanation}</span>;
            }
            return (
              <span
                className={clsx("explanation", {
                  isHovering: hoveredExplanation !== undefined,
                  hovered: isExplanationHovered(explanationAndId),
                  active: isExplanationSelected(explanationAndId),
                  inactive: isExplanationInactive(explanationAndId),
                })}
                onMouseEnter={() => onHoverEnter(explanationAndId)}
                onMouseLeave={onHoverLeave}
                onClick={() => handleExplanationSelection(explanationAndId)}
                key={index}
              >
                {explanationAndId.explanation}
              </span>
            );
          })}
        </div>
        {explanationSteps && <ExplanationSteps />}
      </div>
    );
  }

  return (
    <div>
      <div data-cy="explanation-content">
        {sourcedExplanationWithIds.map(
          ({ explanationAndIds, sourcedExplanationStrings }, index) => {
            let isSource = false;
            return sourcedExplanationStrings.map(
              (sourcedExplanationString, index) => {
                const key = `${sourcedExplanationString}_${index}`;

                if (sourcedExplanationString === OPENING_SOURCE_BRACKET) {
                  isSource = true;
                  return null;
                }

                if (sourcedExplanationString === CLOSING_SOURCE_BRACKET) {
                  isSource = false;
                  return null;
                }

                if (!isSource) {
                  if (!allowSelection) {
                    return <span key={key}>{sourcedExplanationString}</span>;
                  } else {
                    return (
                      <span
                        onMouseEnter={() => onHoverEnter(explanationAndIds)}
                        onMouseLeave={onHoverLeave}
                        onClick={() =>
                          handleExplanationSelection(explanationAndIds)
                        }
                        className={clsx("explanation", {
                          isHovering: hoveredExplanation !== undefined,
                          hovered: isExplanationHovered(explanationAndIds),
                          active: isExplanationSelected(explanationAndIds),
                          inactive: isExplanationInactive(explanationAndIds),
                        })}
                        key={key}
                      >
                        {sourcedExplanationString}
                      </span>
                    );
                  }
                }

                return (
                  <React.Fragment key={key}>
                    <a
                      className="text-black"
                      href={sources.get(sourcedExplanationString)?.[0]}
                      target="_blank"
                      rel="noreferrer"
                      key={key}
                    >
                      <sup>{sourcedExplanationString.trim()}</sup>
                    </a>
                    <sup>
                      {sourcedExplanationStrings[index + 1] ===
                      CLOSING_SOURCE_BRACKET
                        ? ""
                        : ","}
                    </sup>
                  </React.Fragment>
                );
              }
            );
          }
        )}
      </div>

      <div
        className={`text-sm text-base-slate-600 flex flex-col mt-2 sources ${
          sourcesLoaded ? "loaded" : ""
        }`}
        data-cy="explanation-sources"
      >
        <SourceLinks sources={sources} />
      </div>

      {explanationSteps && <ExplanationSteps />}
    </div>
  );
};
