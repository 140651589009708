import { ReactElement, useEffect, useState } from "react";
import styled from "@emotion/styled";
import { AnimatePresence, motion } from "framer-motion";
import { Message, MessageAuthor, useChat } from "@unlikelyai-magic/ui/chat";
import { Spacing, SpacingProps } from "@unlikelyai-magic/ui/layouts";
import { Heading } from "@unlikelyai-magic/ui/typography";
import { ChatMessage } from "./ChatMessage";
import { MessageInput } from "./MessageInput";

const MessagesContainer = styled(Spacing)`
  flex: 1;
  overflow-y: auto;
  padding-bottom: 2rem;
`;

const EmptyStateContainer = styled(Spacing)`
  flex: 1;
`;

const EmptyChatInput = styled(MessageInput)`
  width: 38rem;
  font-size: 1rem;
`;

type ChatContainerProps = SpacingProps & {
  domainName: string;
};

export const ChatContainer = ({ domainName, ...props }: ChatContainerProps) => {
  const { messages } = useChat();

  /* TODO: Only messages added to the chat should animate. shouldAnimate accomplishes that in a hacky way by only animating messages
  after the component has mounted. There is likely a much better way of doing this that we should explore should we keep the chat
  interface in the future. */
  const [shouldAnimate, setShouldAnimate] = useState(false);
  useEffect(() => setShouldAnimate(true), []);

  const animateMessage = (message: Message): ReactElement => (
    <motion.div
      key={message.id}
      initial={
        shouldAnimate
          ? {
              opacity: 0,
              translateY: message.author === MessageAuthor.Bot ? -24 : 24,
            }
          : false
      }
      animate={{ opacity: 1, translateY: 0 }}
    >
      <ChatMessage message={message} />
    </motion.div>
  );

  return (
    <Spacing {...props} gap="none">
      {messages.length > 0 ? (
        <>
          <MessagesContainer gap="xl">
            <AnimatePresence mode="popLayout">
              {messages.map(animateMessage)}
            </AnimatePresence>
          </MessagesContainer>
          <MessageInput />
        </>
      ) : (
        <EmptyStateContainer justify="center" items="center" gap="xl">
          <Heading level="h3">What can I help you with?</Heading>
          <EmptyChatInput placeholder={`Question ${domainName}`} />
        </EmptyStateContainer>
      )}
    </Spacing>
  );
};
