import { useSettingsSidebar } from "@dip/config";
import { SecondaryButton } from "@dip/ui/components/buttons";
import { CodeTextBox } from "@dip/ui/components/text-boxes";
import styled from "@emotion/styled";
import { Spacing } from "@unlikelyai-magic/ui/layouts";
import { useReasoningGraph } from "../../../hooks";

const Container = styled(Spacing)`
  flex: 1;
  justify-content: flex-start;
`;

const GraphButton = styled(SecondaryButton)`
  border-radius: ${({ theme }) => theme.roundness.sm};
`;

type DecisionFeedbackWidgetProps = {
  id: string;
  explanation: string;
};

export const DecisionFeedbackWidget = ({
  id,
  explanation,
}: DecisionFeedbackWidgetProps) => {
  const { showAnsweringUL } = useSettingsSidebar();
  const { activeKey, setActiveKey, updateGraph } = useReasoningGraph();

  const handleOpenGraph = () => {
    updateGraph({ id, isVisible: true });
    setActiveKey(id);
  };

  return (
    <Container>
      {showAnsweringUL && <CodeTextBox header="TRACE" value={explanation} />}
      <Spacing direction="horizontal" gap="xs">
        <GraphButton onClick={handleOpenGraph} disabled={activeKey === id}>
          Graph
        </GraphButton>
      </Spacing>
    </Container>
  );
};
