import { ComponentProps, useEffect, useRef } from "react";
import styled from "@emotion/styled";
import { Message, MessageAuthor, useChat } from "@unlikelyai-magic/ui/chat";
import { Spacing, SpacingProps } from "@unlikelyai-magic/ui/layouts";
import { Paragraph } from "@unlikelyai-magic/ui/typography";
import { BotAvatar } from "./BotAvatar";
import { ChatBubble } from "./ChatBubble";
import { ChatTyping } from "./ChatTyping";

const AvatarContainer = styled.div`
  padding-top: 0.25rem;
`;

const ChatWidgetContainer = styled.div<{ message: Message }>`
  display: flex;
  flex-direction: row;
  padding-top: 1rem;
  padding-left: 3.25rem;
  justify-content: center;
`;

type MessageContainerProps = SpacingProps & {
  author: MessageAuthor;
  previousAuthor: MessageAuthor;
};

export const MessageContainer = styled(
  ({ author, previousAuthor, ...spacingProps }: MessageContainerProps) => (
    <Spacing {...spacingProps} />
  )
)`
  margin-top: ${({ author, previousAuthor }) =>
    author === previousAuthor ? "0" : "0.25rem"};
  gap: 0;
`;

type ChatMessageProps = ComponentProps<"div"> & {
  message: Message;
};

export const ChatMessage = styled(
  ({ message, ...divProps }: ChatMessageProps) => {
    const messageEndRef = useRef<null | HTMLDivElement>(null);
    const { messages } = useChat();
    const nextMessageAuthor = messages[messages.indexOf(message) + 1]?.author;
    const previousMessageAuthor =
      messages[messages.indexOf(message) - 1]?.author;

    useEffect(() => {
      const scrollToBottom = () =>
        messageEndRef.current?.scrollIntoView({ behavior: "smooth" });

      // Slight delay to wait for all components to render and animate before scrolling to the bottom
      const timeoutId = setTimeout(() => {
        requestAnimationFrame(scrollToBottom);
      }, 100);

      return () => clearTimeout(timeoutId);
    }, [messages.length]);

    const showTyping = !message.text && message.author === MessageAuthor.Bot;

    return (
      <MessageContainer
        author={message.author}
        previousAuthor={previousMessageAuthor}
      >
        <div {...divProps}>
          {message.author === MessageAuthor.Bot && (
            <AvatarContainer>
              <BotAvatar
                data-testid="bot-avatar"
                // only show the avatar on the last message in a chain of messages from the same author
                visible={nextMessageAuthor !== MessageAuthor.Bot}
              />
            </AvatarContainer>
          )}
          <ChatBubble author={message.author}>
            {showTyping ? (
              <ChatTyping />
            ) : typeof message.text === "string" ? (
              <Paragraph>{message.text}</Paragraph>
            ) : (
              message.text
            )}
          </ChatBubble>
        </div>
        {message.widget && (
          <ChatWidgetContainer message={message}>
            {message.widget}
          </ChatWidgetContainer>
        )}
        <div ref={messageEndRef} />
      </MessageContainer>
    );
  }
)`
  display: flex;
  align-items: flex-start;
  justify-content: ${({ message }) =>
    message.author === MessageAuthor.Bot ? "flex-start" : "flex-end"};
  gap: 0.5rem;
`;
