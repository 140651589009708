import {
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  createContext,
  useCallback,
  useState,
} from "react";
import {
  ExplanationAndIds,
  GraphAndExplanationProps,
} from "@unlikelyai-magic/core";

type Graph = GraphAndExplanationProps & { id: string; isVisible: boolean };

export interface ReasoningGraphContext {
  activeKey: string | undefined;
  setActiveKey: Dispatch<SetStateAction<string | undefined>>;
  graphs: Graph[];
  updateGraph: (updatedGraphData: Partial<Graph>) => void;
  hoveredExplanation?: ExplanationAndIds;
  setHoveredExplanation: Dispatch<
    SetStateAction<ExplanationAndIds | undefined>
  >;
}

export const ReasoningGraphContext = createContext<ReasoningGraphContext>(
  {} as ReasoningGraphContext
);

export const ReasoningGraphProvider = ({ children }: PropsWithChildren) => {
  const [activeKey, setActiveKey] = useState<string>();
  const [graphs, setGraphs] = useState<Graph[]>([]);
  const [hoveredExplanation, setHoveredExplanation] =
    useState<ExplanationAndIds>();

  const updateGraph = useCallback(
    (updatedGraphData: Partial<Graph>) =>
      setGraphs((prevGraphs) => {
        const indexToUpdate = prevGraphs.findIndex(
          ({ id }) => id === updatedGraphData.id
        );
        const updatedGraph = {
          ...prevGraphs[indexToUpdate],
          ...updatedGraphData,
        };
        return indexToUpdate === -1
          ? [...prevGraphs, updatedGraph]
          : [
              ...prevGraphs.slice(0, indexToUpdate),
              updatedGraph,
              ...prevGraphs.slice(indexToUpdate + 1),
            ];
      }),
    []
  );

  return (
    <ReasoningGraphContext.Provider
      value={{
        activeKey,
        setActiveKey,
        graphs,
        updateGraph,
        hoveredExplanation,
        setHoveredExplanation,
      }}
    >
      {children}
    </ReasoningGraphContext.Provider>
  );
};
