import { useContext, useEffect, useState } from "react";
import { NodeDto } from "@dip/data-access/api-types";
import { useGetReasoningGraphDataQuery } from "@dip/data-access/dip-api-service";
import { SecondaryButton } from "@dip/ui/components/buttons";
import styled from "@emotion/styled";
import {
  FinalQuestionState,
  NicknameDataContext,
  PreferredTranslation,
  REExplanationTranslationResponse,
  ResponseTopLevelAnswer,
  getFinalState,
  questionStateFromLogs,
  serialiseUlElementLlAware,
  useTranslator,
} from "@unlikelyai-magic/core";
import { MessageAuthor, useChat } from "@unlikelyai-magic/ui/chat";
import { Spacing } from "@unlikelyai-magic/ui/layouts";
import { useReasoningGraph } from "../../../hooks";
import { DecisionFeedbackWidget } from "./DecisionFeedbackWidget";
import { ExplanationTooltip } from "./ExplanationTooltip";

const Container = styled(Spacing)`
  justify-content: flex-start;
  width: 100%;
`;

const ExplainButton = styled(SecondaryButton)`
  border-radius: ${({ theme }) => theme.roundness.sm};
`;

const ExplanationText = styled.span`
  font-size: 1rem;
  &:hover {
    color: ${({ theme }) => theme.colors.action.link.default};
    cursor: pointer;
  }
`;

type ExplanationWidgetProps = {
  messageId: string;
  nodes: NodeDto[];
  explanation: string;
  debugLogId?: string;
};

export const ExplanationWidget = ({
  nodes,
  messageId,
  explanation,
  debugLogId,
}: ExplanationWidgetProps) => {
  const { updateGraph, activeKey, setActiveKey } = useReasoningGraph();
  const { pushMessage, latestBotMessageId, updateMessage } = useChat();

  const [explanationMessageId, setMessageExplanationId] = useState<string>();
  const [showTooltip, setShowTooltip] = useState(false);

  const translator = useTranslator();
  const { data: reasoningGraphData } = useGetReasoningGraphDataQuery(
    { debugLogId: debugLogId || "" },
    { skip: !debugLogId }
  );
  const [questionState, setQuestionState] = useState<FinalQuestionState>();
  const [explanationTranslation, setExplanationTranslation] =
    useState<REExplanationTranslationResponse>();
  const answer: ResponseTopLevelAnswer = questionState?.topLevelAnswers
    .values()
    .next().value;

  const { uuidToNicknameMap } = useContext(NicknameDataContext);

  useEffect(() => {
    if (!reasoningGraphData) return;

    const questionState = questionStateFromLogs(reasoningGraphData);
    if (!questionState) {
      return;
    }

    const finalState = getFinalState(questionState);
    setQuestionState(finalState);

    const graphAnswer = questionState.topLevelAnswers.values().next().value;
    const explanationAndContext = {
      question: serialiseUlElementLlAware(
        questionState.query,
        uuidToNicknameMap,
        true,
        false
      ),
      explanation: graphAnswer.answer.explanation,
      id: graphAnswer.answer.solutionId,
      solutionMapping: graphAnswer.answer.mapping,
    };

    translator
      .getSummarisedREExplanationTranslation(
        explanationAndContext.question,
        explanationAndContext.id,
        explanationAndContext.solutionMapping,
        explanationAndContext.explanation,
        {
          selectedTranslators: ["nmt-translator"],
          preferredTranslations: nodes
            .filter(({ resolved }) => !resolved)
            .map(
              (node): PreferredTranslation => ({
                text: node.expressedIn,
                ul_element: {
                  uuid: node.nodeId,
                },
              })
            ),
        }
      )
      .then(setExplanationTranslation)
      .catch(console.error);
  }, [reasoningGraphData, translator, nodes, uuidToNicknameMap]);

  useEffect(() => {
    if (!(explanationTranslation && explanationMessageId)) return;
    if (questionState)
      updateGraph({
        id: explanationMessageId,
        finalQuestionState: questionState,
        answer: answer?.answer,
        translatedExplanation: explanationTranslation,
      });
    updateMessage({
      id: explanationMessageId,
      text: (
        <>
          {explanationTranslation.summarisedTranslation.map((translation) => (
            <ExplanationText
              key={`${
                translation.explanation
              } ${translation.solutionIds.join()}`}
              onMouseEnter={() => {
                updateGraph({
                  id: explanationMessageId,
                  hoveredExplanation: translation,
                });

                setShowTooltip(explanationMessageId !== activeKey);
              }}
              onMouseLeave={() => {
                updateGraph({
                  id: explanationMessageId,
                  hoveredExplanation: undefined,
                });

                setShowTooltip(false);
              }}
              onClick={() => {
                updateGraph({
                  id: explanationMessageId,
                  isVisible: true,
                });

                setActiveKey(explanationMessageId);
                setShowTooltip(false);
              }}
            >
              {translation.explanation}
            </ExplanationText>
          ))}
        </>
      ),
      widget: (
        <DecisionFeedbackWidget
          /* TODO: Use id from decision endpoint here instead of a uuid when decision endpoint properly returns one
           See https://linear.app/unlikelyai/issue/UA-458/decisions-endpoint-returns-null-for-the-id-and-datecreated-fields. */
          id={explanationMessageId}
          explanation={explanation}
        />
      ),
    });
  }, [
    activeKey,
    answer?.answer,
    explanation,
    explanationMessageId,
    explanationTranslation,
    questionState,
    setActiveKey,
    updateGraph,
    updateMessage,
  ]);

  const showExplanation = () => {
    pushMessage({
      author: MessageAuthor.User,
      text: "Explain this answer",
    });
    setMessageExplanationId(
      pushMessage({
        author: MessageAuthor.Bot,
      })
    );
  };

  return (
    <Container>
      <Spacing direction="horizontal" gap="xs">
        {debugLogId && messageId === latestBotMessageId && (
          <ExplainButton onClick={showExplanation}>Explain</ExplainButton>
        )}
        {showTooltip && <ExplanationTooltip />}
      </Spacing>
    </Container>
  );
};
