import { ReactNode } from "react";
import { PageLayout as DIPPageLayout } from "@dip/ui/layouts";
import styled from "@emotion/styled";
import { ArrowLeftIcon } from "@heroicons/react/24/outline";
import { Button, Col, Row } from "antd";

const PageLayout = styled(DIPPageLayout)`
  width: 50%;
  margin: ${({ theme }) => theme.spacings.lg} auto;
`;

const BackButtonColumn = styled(Col)`
  text-align: right;
`;

const TitleColumn = styled(Col)`
  margin-top: auto;
  margin-bottom: auto;
`;

type StepLayoutProps = {
  title: ReactNode;
  headerDescription: ReactNode;
  content?: ReactNode;
  onGoBack?: () => void;
};

export const StepLayout = ({
  title,
  headerDescription,
  content,
  onGoBack,
}: StepLayoutProps) => (
  <>
    <PageLayout>
      <Row gutter={[16, 8]}>
        <BackButtonColumn span={2}>
          {onGoBack && (
            <Button type="text" icon={<ArrowLeftIcon />} onClick={onGoBack} />
          )}
        </BackButtonColumn>
        <TitleColumn span={22}>{title}</TitleColumn>
        <Col span={22} offset={2}>
          {headerDescription}
        </Col>
      </Row>
    </PageLayout>
    {content && (
      <Row>
        <Col flex="auto">{content}</Col>
      </Row>
    )}
  </>
);
