import { ReactNode } from "react";
import styled from "@emotion/styled";
import { MessageAuthor } from "@unlikelyai-magic/ui/chat";

const Bubble = styled.div`
  border-radius: ${({ theme }) => theme.roundness.md};
  padding: 0.5rem 1rem;
  max-width: 80%;
  word-break: break-word;
  overflow-wrap: break-word;
  color: ${({ theme }) => theme.colors.text.primary.default};
`;

const BotBubble = styled(Bubble)`
  background: transparent;
`;

const UserBubble = styled(Bubble)`
  background: ${({ theme }) => theme.colors.component.background[1]};
  border: 0.0625rem solid ${({ theme }) => theme.colors.component.border[1]};
`;

export type ChatBubbleProps = {
  author: MessageAuthor;
  children: ReactNode;
};

export const ChatBubble = ({ children, author }: ChatBubbleProps) =>
  author === MessageAuthor.Bot ? (
    <BotBubble data-testid="bot-bubble">{children}</BotBubble>
  ) : (
    <UserBubble data-testid="user-bubble">{children}</UserBubble>
  );
